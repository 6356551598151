import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import BACKGROUND_COLORS_CLASSES from '../constants/BACKGROUND_COLORS_CLASSES'

export const paginationCva = cva(
  [`${BACKGROUND_COLORS_CLASSES.accent1} p-2 transition h-10 w-10`],
  {
    variants: {
      type: {
        previous: ['rounded-l-md'],
        next: ['rounded-r-md']
      },
      disabled: {
        true: ['cursor-not-allowed opacity-50 hover:bg-slate-200'],
        false: [' hover:bg-slate-300']
      }
    },
    defaultVariants: {
      disabled: false
    }
  }
)

export type PaginationProps = VariantProps<typeof paginationCva>
